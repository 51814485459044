<template>
    <div class="order-detail-wrapper" v-if="orderData">
        <search />
        <div class="section white">
            <div class="section-hd" >{{$t('order status')}}：{{translatestatus(orderData.status.data.field.text)}}</div>
        </div>
        <div class="section">
            <div class="section-hd">{{$t('order information')}}</div>
            <div class="section-bd">
                <ul>
                    <li class="acea-row row-middle" v-for="(item,index) in orderData.info.data.list" :key="index">
                        <div>{{ hardcodedLabels[index] }}：</div>
                        <div>{{ item.text }}</div>
                    </li>
                </ul>
            </div>
        </div>

<!-- Shipping info section will not be created if uniacid is 24 -->
<template v-if="uniacid !== 24">
<div  class="section white">
    <div class="section-hd">{{$t('Shipping info')}}</div>
    <div class="section-bd">
        <ul>
            <li class="acea-row row-middle">
                <div>{{$t('Receiver')}}：</div>
                <div>{{orderData.address.data.field.contact}}</div>
            </li>
            <li class="acea-row row-middle">
                <div>{{$t('contact number')}}：</div>
                <div>{{orderData.address.data.field.contact_number}}</div>
            </li>
            <li class="acea-row">
                <div>{{$t('Shipping address')}}：</div>
                <div>{{orderData.address.data.field.full_address}}</div>
            </li>
        </ul>
    </div>
</div>
</template>

        <div class="section white">
            <div class="section-hd">{{$t('product information')}}</div>
            <div  class="section-bd">
                <ul  class="goods">
                    <li class="acea-row row-middle" v-for="(child,childIndex) in orderData.goods.data.list" :key="childIndex">
                        <div>
                            <img :src="child.goods_image" alt />
                        </div> 
                        <div>
                            <div>{{translateGoodsName(child.goods_name)}}</div>
                            <div class="info">{{child.goods_sku_text ? child.goods_sku_text : '默認'}}</div> 
                            <div>

     


                                <template v-if="orderData.basic.data.field.order_currency === 'USD'">
                                    <span class="money">US${{child.goods_us_amount}}</span>
                                </template>
                                <template v-else>
                                    <span class="money">{{child.danwei + child.goods_amount}}</span>
                                </template>


                                <span > X{{child.goods_number}}</span>
                            </div>
                        </div>
                    </li>
                </ul> 
                <!-- <ul>
                    <li class="acea-row row-middle">
                        <div >运费：</div> 
                        <div >18.00</div>
                    </li>
                </ul> -->
                <ul class="total">
                    <li class="acea-row row-middle row-between">
                        <div>{{$t('The total order amount is')}}：<span class="money"> 
                            <template v-if="orderData.basic.data.field.order_currency === 'USD'">
                                
                                <b >US${{orderData.basic.data.field.order_amount}}</b>
                                </template>
                                <template v-else>
                                    <b >{{orderData.basic.data.field.order_amount}}</b>
                                </template>
                        </span></div>
                            <div class="footerState acea-row row-middle">
                            <!-- <div  class="orderBnt">取消訂單</div>  -->
                            <div  class="orderBnt on" v-if="orderData.status.data.field.text=='待支付'" @click="getPaymentList">{{$t('payment now')}}</div> 
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- pay -->
<el-dialog
     :title="$t('Select Payment Method')"
    :visible.sync="dialogVisible"
    width="50%"
    :before-close="handleClose">
    <el-radio-group v-model="pay_type" @change="handleRadioChange">
        <el-radio v-for="(item, index) in paymentList" :key="index" :disabled="item.disabled" :label="item.code">
            {{ hardcodedpayments[index]}}
        </el-radio>
        <el-radio v-if="yedpay === true" label="yedpay">{{$t('Online Payment')}}</el-radio>
    </el-radio-group>
    
    <span slot="footer" class="dialog-footer">
        <!-- PayPal specific footer -->
        <template v-if="pay_type === 'paypal'">
            <div id="paypal-button-container"></div>
            <el-button @click="dialogVisible = false">{{$t('Cancel')}}</el-button>
            <el-button type="primary" v-show="confirmVisible" @click="pay">{{$t('Confirm')}}</el-button>
        </template>
        
        <!-- Other payment methods footer -->
        <template v-else>
            <el-button @click="dialogVisible = false">{{$t('Cancel')}}</el-button>
            <el-button type="primary" v-show="confirmVisible" @click="confirmPayment">{{$t('Confirm')}}</el-button>
        </template>
    </span>

    
</el-dialog>


    </div>
</template>
<script>
import Search from '@/components/Search'
import translations from '@/assets/translations.json'
export default {
    components: {Search},
    data() {
        return {
            confirmVisible: true,
			//uniacid:0,
            uniacid:parseInt(window.localStorage.getItem('uniacid')),
            //uniacid:19,
            orderData: null,
            paymentList: [
                { code: 'type1'},
                { code: 'type2'},
            ],
            pay_type: '',
            dialogVisible: false,
            dialogPaypalVisible: false,
            currency: window.localStorage.getItem('currency'),
            paypalData:null,
            ordersn: '',
            locale: window.localStorage.getItem('locale'),
        };
    },
    computed: {
    hardcodedLabels() {
        if (this.locale === 'EN') {
            return [
                'order_sn',
                'currency',
                'order_time',
                'pay_time',
                'cancel_time'
            ];
        } else {
            return this.orderData.info.data.list.map(item => item.label);
        }
    },
    hardcodedpayments() {
        if (this.locale === 'EN' ) {
            return [
                'Collect on delivery',
                'Paypal',
                'Balance payment',

            ]}
            else {
                return this.paymentList.map(item => item.name);
            }}
},
    created() {
		if(this.$route.query.order_id){
			 this.getOrderInfo(this.$route.query.order_id)
		}else{
			 this.getOrderInfo(this.$route.params.order)
		}
        var url = window.location.href;
        var dz_url = url.split("#")[0];
        var www_dz_url = dz_url.split("/")[2];
        var centerdomain= www_dz_url.split(".");
        if(centerdomain[0]=='alex' || this.uniacid == 15){
            this.yedpay=true;

            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AYk6yzGsSK4csLS6gaKLLTJOKLlqaLg7Qz8wn7eoCsCcbj_KJOeE-wdpeYNrR15cypT0FXt1pK5yJ0FM&currency=HKD");
            document.head.appendChild(tag);
        }
        else if(centerdomain[0]=='nm' || this.uniacid == 17){
            this.yedpay=true;
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AXtLnSrEsxn4TbzQ1dOt7kL7tBSQx5CrFBEGOabjJCdCp7Mr8HU1bqa-IisrDtCoTGKMMT45jjti4rJa&currency=HKD");
            document.head.appendChild(tag);
        }
        else if(centerdomain[0]=='ace' || this.uniacid == 19){
            this.yedpay=true;

            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AVBI7idWVEcagwi3IvKwNTwAYFPcfguSr6LePapIWdZNuzAGZsYdj3IJB-m5eD-pK8YSnKeJOB5wTArv&currency=HKD");
            document.head.appendChild(tag);
        }
        else if(centerdomain[0]=='uf' || this.uniacid == 20){
            this.yedpay=true;
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AU7iIVdB-LPIZ88IWIfG7fBGrzIlrdUZr5DM2--RGSM5XdEKjmgljhRnRJHgOnUE92XgdIky86lh5SUe&currency=HKD");
            document.head.appendChild(tag);

        }
        else if(centerdomain[0]=='hc' || this.uniacid == 22){
            this.yedpay=true;
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=ATqYP_MtbQpiV0IzspbFqullRRZUhuw4wPKijkC4fglz7T6i87Lg_My0j5SMSifKxE7FZtEV86IX1xBQ&currency=HKD");
            document.head.appendChild(tag);

        }
        else if(centerdomain[0]=='trendy' || this.uniacid == 26){
            //this.yedpay=true;
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AZfZ-ex0qwP0r90lnVCapuIFXFUSvD5Y_hL1omoQzL4fyMyJfZFtiBm8_asg_WDBMd2lpWNc0vCkLocZ&currency=HKD");
            document.head.appendChild(tag);

        }

        
    },
    methods: {
        translateGoodsName(goodsName) {
            if (this.locale === 'EN') {
                return translations[goodsName] || goodsName; // Use translations from JSON
            }
            return goodsName; // Return original name if locale is not 'EN'
        },
        translatestatus(statusName) {
        if (this.locale === 'EN') {
            // Mapping of Chinese names to English names
            const translations = {
                '待支付': 'Waiting for payment',
                "已支付": "Paid",
                '已取消': 'Cancelled',
            };
            return translations[statusName] || statusName; // Return translated name or original if not found
        }
        return statusName; // Return original name if locale is not 'EN'
    },
        confirmPayment() {
            this.confirmVisible = false; // Hide the Confirm button
            this.pay(); 
        },
        async getOrderInfo(order_sn) {
            const res = await this.$http.get('/order/detail', {order_sn})
            if (res.code !== 0) {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
            let data = res.data
            this.orderData = data
            this.ordersn =this.orderData.basic.data.field.order_sn
        },
        //選擇支付方式
        async getPaymentList() {
			
			 //this.uniacid =20;
             //this.uniacid = 19;
            const res = await this.$http.get('/pay', {
                order_sn: this.orderData.basic.data.field.order_sn,v:5,uniacid:this.uniacid
            })
            if (res.code !== 0) {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
            this.paymentList = res.data.list
            this.dialogVisible = true
        },
        handleRadioChange(value) {
            if (value !== 'paypal') {
                this.isPaypalCalled = false;
                this.confirmVisible = true;
            }
        },
        async pay() {
            // Only hide confirmation dialog for PayPal
            if (this.pay_type === 'paypal') {
                this.confirmVisible = false;
                this.isPaypalCalled = true;
            } else {
                this.isPaypalCalled = false;
                this.confirmVisible = true;
            }
			console.log('paytype',this.pay_type);
            if(!this.pay_type){
                return this.$message.error('請選擇支付方式')
            }
			let gourl1="https://tvp-w7.nowaittech.com/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=api_payasia_pc_wechat&pay_type="+this.pay_type
			    gourl1+="&amount="+this.orderData.basic.data.field.order_amount
			    gourl1+="&order_sn="+this.orderData.basic.data.field.order_sn
			console.log('payasia....');
			
            if(this.pay_type=='yedpay'){
                let yedpayurl="https://tvp-w7.nowaittech.com/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=api_yedpay";
                yedpayurl+="&order_sn="+this.orderData.basic.data.field.order_sn
				console.log('yedpay');
				window.location.href= yedpayurl
				return 
			}
			if(this.pay_type=='payasia'){
				console.log('payasia....');
				window.location.href= gourl1
				return 
			}
			if(this.pay_type=='paypal'){
				let gourl="https://tvp-w7.nowaittech.com/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=paypal33"
				    gourl+="&price="+this.orderData.basic.data.field.order_amount
				    gourl+="&tcid="+this.orderData.basic.data.field.order_sn
				console.log('paypal....');
                
                
                //this.dialogPaypalVisible=true;
				//window.location.href= gourl
                this.showPaypal(gourl, this.uniacid, this.ordersn);

                /*
                paypal.Buttons({
                // Order is created on the server and the order id is returned
                createOrder() {
                    return fetch(gourl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        // use the "body" param to optionally pass additional order information
                        // like product skus and quantities
                        body: JSON.stringify({
                            cart: [
                                    {
                                    sku: "YOUR_PRODUCT_STOCK_KEEPING_UNIT",
                                    quantity: "YOUR_PRODUCT_QUANTITY",
                                    },
                                ],
                            }),
                        })
                        .then((response) => {
                            response.json();
                            console.log('responseeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
                            console.log(response.json());
                        })
                        .then((order) => {
                            order.id
                        });
                    }
                }).render('#paypal-button-container');*/
				return 
			}
			if(this.pay_type=='payasiazfb'){
				console.log('payasia....');
				window.location.href= gourl1
				return  
			}		
			if(this.pay_type=='payasiawechat'){
			
				window.location.href= gourl1
				return 
			}
            const res = await this.$http.post('/pay/'+this.pay_type, {
                order_sn: this.orderData.basic.data.field.order_sn
            })
            if(res.code == 0){
                 this.$message({message: res.message, type: 'success'})
                let timer = setTimeout(() => {
                    this.$router.push({name: 'User'})
                }, 500);
            }else{
                  return this.$message.error(res.message)
            }
        },
        showPaypal(gourl, uniacid, ordersn) {
            paypal.Buttons({
                    style: {
                        layout: 'horizontal'
                    },
                    createOrder: function(data, actions) {
                        return fetch(gourl, {
                            method: 'post'
                        }).then(function(res) {
                            return res.json();
                        }).then(function(orderData) {
                            console.log('orderDataaaaaaaaaaaaaaaaaa')
                            console.log(orderData)
                            //this.paypalData = orderData;
                            return orderData.id;
                        });
                    },
                    onApprove(data) {
                        console.log('dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
                        console.log(data)
                        console.log('data.orderID: ' + data.orderID)
                        let gourl2="https://tvp-w7.nowaittech.com/app/index.php?i="+uniacid+"&c=entry&m=yumi_jinxiaocun&do=paypal33_capture"
				        gourl2+="&tcid="+ordersn
                        gourl2+="&orderid="+data.orderID
                        console.log('gourl2: ' + gourl2)
                        return fetch(gourl2, {
                            method: "post",
                            mode: "no-cors",
                        })
                        .then((response) => {
                            console.log('responseeeeeee')
                            console.log(response)
                            response.json()
                        })
                        .then((orderData) => {
                            console.log('orderrrrrrrrrrrrrrrrrrrrrrr')
                            console.log(orderData)

                            let backurl = "/#/user?type=0";
                            window.location.href= backurl;


                            // Successful capture! For dev/demo purposes:
                            //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                            //const transaction = orderData.purchase_units[0].payments.captures[0];
                            //alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
                            // When ready to go live, remove the alert and show a success message within this page. For example:
                            // const element = document.getElementById('paypal-button-container');
                            // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                            // Or go to another URL:  window.location.href = 'thank_you.html';
                        });
                    }
                }).render('#paypal-button-container');
        },
        handleClose() {
			this.dialogVisible=false;
            this.dialogPaypalVisible=false;
        }
    },

};
</script>
<style lang="scss" scoped>
.order-detail-wrapper {
    .header {
        height: 60px;
        line-height: 60px;
        color: #999999;
        background-color: unset;
        .home {
            color: #282828;
        }
    }
    .white{
         background: #ffffff;
    }

    > div {
       

        &.order-number {
            li {
                div {
                    &:nth-child(2) {
                        flex: none;
                    }
                }

                a {
                    margin-left: 30px;
                    font-size: 16px;
                    color: #236fe9;

                    .iconfont {
                        font-size: 12px;
                    }
                }
            }
        }

        ~ div {
            margin-top: 14px;
        }

        > div {
            ~ div {
                border-top: 1px dashed #cecece;
            }
        }

        &.process {
            margin-top: 0;
            div {
                border-top: none;
                &.section-hd {
                    padding: 26px 22px 0;
                }
                ul {
                    padding: 27px 0 94px;
                    &::after {
                        content: "";
                        display: block;
                        height: 0;
                        clear: both;
                        visibility: hidden;
                    }
                }
                li {
                    position: relative;
                    float: left;
                    margin-top: 0;
                    margin-left: 222px;
                    &:first-child {
                        margin-left: 111px;
                    }
                    .line {
                        position: absolute;
                        top: 50%;
                        left: 16px;
                        width: 226px;
                        height: 4px;
                        background: #c7c7c7;
                        transform: translateY(-50%);
                    }
                    .iconfont {
                        position: relative;
                        width: auto;
                        font-size: 18px;
                        line-height: 1;
                        color: #c7c7c7;

                        + .iconfont {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            display: none;
                            width: 40px;
                            height: 40px;
                            border: 4px solid #e93323;
                            border-radius: 50%;
                            background: #ffffff;
                            transform: translate(-50%, -50%);
                            font-size: 20px;
                            line-height: 32px;
                            text-align: center;
                            color: #e93323;
                        }
                    }
                    .arrow {
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        display: none;
                        width: 80px;
                        height: 16px;
                        background: #e93323;
                        transform: translateY(-50%);
                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 100%;
                            border-width: 8px;
                            border-style: solid;
                            border-color: transparent transparent transparent
                                #e93323;
                        }
                    }
                    .info {
                        position: absolute;
                        top: 42px;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 14px;
                        text-align: center;
                        color: #9a9a9a;
                        width: 100px;
                        div {
                            &:first-child {
                                margin-bottom: 4px;
                                font-size: 16px;
                                color: #282828;
                            }
                        }
                    }
                    &.past {
                        .line {
                            background: rgba(233, 51, 35, 0.6);
                        }
                        .iconfont {
                            color: #e93323;
                        }
                    }
                    &.now {
                        .info {
                            div {
                                &:first-child {
                                    color: #e93323;
                                }
                            }
                        }
                        .iconfont {
                            + .iconfont {
                                display: block;
                            }
                        }
                        .arrow {
                            display: block;
                        }
                    }
                }
            }
        }
        &.reject {
            position: relative;
            padding: 30px 22px;
            background: #666666;
            overflow: hidden;
            margin-top: 0;
            .iconfont {
                position: absolute;
                top: -20px;
                right: 28px;
                font-size: 112px;
                color: #818181;
            }
            div {
                border-top: none;
                &.section-hd {
                    padding: 0;
                    font-weight: bold;
                    color: #ffffff;
                }
                ul {
                    padding: 0;
                    margin-top: 8px;
                    font-size: 14px;
                    color: #ffffff;
                }
            }
        }
        &.reason {
            padding: 26px 22px;
            div {
                border-top: none;
                &.section-hd {
                    padding: 0;
                    .iconfont {
                        margin-right: 8px;
                    }
                }
                ul {
                    padding: 0;
                    margin-top: 15px;
                    color: #7e7e7e;
                }
            }
        }
    }
    .section-hd {
        padding: 18px 22px;
        font-size: 18px;
        color: #282828;
    }
    .section-bd {
        ul {
            padding: 22px;
            font-size: 16px;
            color: #282828;
            ~ ul {
                border-top: 1px dashed #cecece;
            }
        }
        li {
            .time {
                margin-left: 10px;
            }
            ~ li {
                margin-top: 20px;
            }
            > div {
                &:first-child {
                    width: 80px;
                }
                &:nth-child(2) {
                    flex: 1;
                }
            }
            &.coupon {
                span {
                    ~ span {
                        margin-left: 18px;
                    }
                }
            }
        }
        .money {
            color: #e93323;
            b {
                font-weight: normal;
                font-size: 22px;
            }
        }
        .goods {
            width: 60%;
            .info {
                font-size: 12px;
                color: #aaa;
                margin-top: 4px;
            }
            li {
                position: relative;
                ~ li {
                    margin-top: 22px;
                }
                > div {
                    &:nth-child(1) {
                        width: 86px;
                        height: 86px;
                        overflow: hidden;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &:nth-child(2) {
                        margin-right: 56px;
                        margin-left: 26px;

                        > div {
                            &:first-child {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                            }
                            &:last-child {
                                margin-top: 10px;
                                del {
                                    margin-left: 12px;
                                    font-size: 14px;
                                    color: #919191;
                                }
                            }
                        }
                    }
                    &:nth-child(3) {
                        font-size: 14px;
                        color: #b1b1b1;
                    }
                }
            }
        }
        .total {
            padding: 28px 22px;
            .footerState {
                cursor: pointer;
            }
            .service {
                width: 114px;
                height: 40px;
                margin-left: 18px;
                background: #e93323;
                color: #fff;
                font-size: 16px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
            }
            div {
                &:first-child {
                    width: auto;
                }
                &:last-child {
                    flex: none;
                    div {
                        padding-right: 30px;
                        padding-left: 30px;
                        ~ div {
                            border-left: 1px solid #cecece;
                        }
                    }
                    .orderBnt {
                        width: 114px;
                        height: 40px;
                        padding: 0;
                        border: 1px solid #999999;
                        border-radius: 2px;
                        background: none;
                        outline: none;
                        font-size: 16px;
                        line-height: 40px;
                        text-align: center;
                        color: #282828;
                        ~ .orderBnt {
                            margin-left: 18px;
                        }
                        &.on {
                            border-color: #e93323;
                            background: #e93323;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
.evaluate_btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.acea-row{
    display: flex;
    justify-content: flex-start;
}
.section{
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    margin-top: 30px;
    
}
.row-between{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>